import React from 'react';
import utils from "utils/utils";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {useTranslation} from "react-i18next";

const RolesSelect = (
    {
        role,
        setRole,
        isCourse = true
    }
) => {
    const {t} = useTranslation();

    return (
        <Select
            labelId={'select-role'}
            label={t("users.addUsers.role")}
            name={'role'}
            variant={'outlined'}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            width={'100%'}
        >
            {Object.keys(isCourse ? utils.rolesCoursesNames : utils.rolesNames).map((valueRole) =>
                <MenuItem key={valueRole} value={valueRole}>
                    {t(`roles.${isCourse ? utils.rolesCoursesNames[valueRole] : utils.rolesNames[valueRole]}`)}
                </MenuItem>
            )}
        </Select>
    );
};

export default RolesSelect;
