import React, {useEffect, useState} from 'react';
import {Modal, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {miniModal, modalStyle} from "./style";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import RolesSelect from "components/teacher/RolesSelect";
import Button from "@mui/material/Button";
import usersApi from 'api/routes/users';
import {useUser} from "hooks/useUser";
import {useParams} from "react-router-dom";
import {handleDataServer} from "api";

const EditUserModal = (
    {
        open,
        handleClose,
        isCourse = true,
        user = {}
    }
) => {
    const userLogged = useUser();
    const {t} = useTranslation();
    const [role, setRole] = useState("");
    let {idCourse} = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // check if field is fulfilled and it is different from the current role
        if (role && user.role !== role) {
            let result = isCourse ?
                await usersApi.editMember(userLogged.access_token, idCourse, user.id, role) :
                await usersApi.editUser(userLogged.access_token, user.id, role, user.email);
            let data = await handleDataServer(result, (data) => data.data);

            if (data) {
                handleClose(true);
            }
        }
    };

    useEffect(() => {
        if (open) {
            setRole(user.role);
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={[modalStyle, miniModal]}>
                <Typography id="modal-modal-title" variant={'h6'} component={'h2'}>
                    {t('users.edit.title')} {user.name}
                </Typography>
                <div id="modal-modal-description">
                    <Stack direction={'row'} justifyContent={'space-evenly'}>
                        <RolesSelect role={role} setRole={setRole} isCourse={isCourse}/>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t("general.edit")}
                        </Button>
                    </Stack>
                </div>
            </Box>
        </Modal>
    );
};

export default EditUserModal;
