import React, {useEffect, useState} from "react";
import {SERVER_URI} from 'api';
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import {getCourses, selectCourses} from 'store/modules/Courses';

import Card from "@mui/material/Card";
import utils from "utils/utils";
import CourseManagement from "components/teacher/CourseManagement";
import {useDispatch, useSelector} from "react-redux";
import {useUser} from "hooks/useUser";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import ActivityManagement from "components/teacher/ActivityManagement";
import {useTranslation} from "react-i18next";
import {Stack} from "@mui/material";
import moodleImage from "./moodle.png";
import ModalMoodle from "components/teacher/modal/ModalMoodle";

const ReadCourses = () => {
    const {t} = useTranslation();
    const user = useUser();
    const dispatch = useDispatch();
    const courses = useSelector(selectCourses);

    const [documentModal, setDocumentModal] = useState({});
    const [modalMoodle, setModalMoodle] = useState(false);

    const closeModal = () => {
        setModalMoodle(false);
        setDocumentModal({});
    };

    const openModal = (document) => {
        setDocumentModal(document);
        setModalMoodle(true);
    };

    const populate = async () => {
        dispatch(getCourses(user.access_token));
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <>
            <Card>
                <h1>{t("courses.title")}:</h1>

                {user.role === utils.roles.teacher || utils.isAdmin() &&
                    <Button
                        variant="contained"
                        color="primary"
                        className={'marginBottom8'}
                    >
                        <Link to={`/teacher/courses/new`}>
                            {t("general.create")}
                        </Link>
                    </Button>
                }

                {courses && courses.map((course, i) =>
                    <Accordion
                        key={i}
                        style={{borderLeft: "2px #00c76d solid", paddingTop: '5px'}}
                        defaultExpanded={courses.length === 1}
                        disableGutters
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel-courses-content"
                            id="panel-courses-header"
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={2}
                                className={'stack'}
                                sx={{flexDirection: {xs: 'column', md: 'row'}}}
                            >
                                <Typography variant="h6" gutterBottom component="div" className={'bold'}>
                                    {course.name}
                                </Typography>
                                {user.role === utils.roles.teacher || utils.isAdmin() &&
                                    <CourseManagement course={course}/>
                                }
                            </Stack>
                        </AccordionSummary>

                        <AccordionDetails>
                            {course.activities.map((activity, j) =>
                                <Accordion key={j} disableGutters>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="p" component="div">
                                            {activity.name}
                                        </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        {user.role === utils.roles.teacher || utils.isAdmin() &&
                                            <ActivityManagement course={course} activity={activity}/>
                                        }

                                        {activity.documents && activity.documents.map((document, k) =>
                                            <>
                                                <Stack direction={'row'} alignItems={'center'}>
                                                    <img
                                                        onClick={() => openModal(document)}
                                                        src={moodleImage}
                                                        alt="Moodle"
                                                        title="Moodle"
                                                        width="20px"
                                                        height="20px"
                                                        className={'fade pointer'}
                                                    />
                                                    <ListItemButton
                                                        component="a"
                                                        href={`${SERVER_URI}/getDocument?document=${document.id}&token=${user.access_token}`}
                                                        target="_blank"
                                                        key={k}
                                                    >
                                                        <ListItemIcon>
                                                            <LibraryBooksIcon/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={document.title}/>
                                                    </ListItemButton>
                                                </Stack>
                                            </>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </AccordionDetails>
                    </Accordion>
                )}
            </Card>

            <ModalMoodle
                open={modalMoodle}
                handleClose={closeModal}
                document={documentModal}
            />
        </>
    );
};

export default ReadCourses;
