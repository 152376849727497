export default {
    translations: {
        brand: {
            name: "DLNotes",
            description: "Ferramenta de anotação colaborativa em conteúdos digitais voltados ao ensino de línguas",
        },
        auth: {
            login: "Entrar",
            createAccount: "Criar Conta",
            alreadyHaveAccount: "Já tenho uma conta",
            backIndex: "Voltar para a página inicial",
            logout: "Sair",
            roleFailed: "Parece que seu nível de perfil não existe. Contacte um administrador.",
            forgotPassword: "Esqueci minha senha",
            forgot: {
                instructionsEmail: "As instruções de recuperação de acesso foram enviadas para {{email}}"
            },
            confirm: {
                title: "Defina uma senha de acesso",
                passwordSet: "Senha definida com sucesso, você será redirecionado para a página de login",
                confirmPassword: "Confirme a senha",
                setPassword: "Definir senha",
                passwordDiff: "As senhas não conferem"
            },
            create: {
                title: "Criar usuário",
                line0: "É muito bom ter você por aqui!",
                line1: "Você vai receber um e-mail para confirmar sua inscrição, até mais!",
                fullName: "Nome completo"
            }
        },
        common: {
            template: {
                logo: "Logo DLNotes"
            }
        },
        teacher: {
            exportAnnotations: "Exportar anotações",
        },
        stats: {
            stats_free: "Estatística livre",
            stats_semantic: "Estatística semântica",
            telemetry: "Telemetria",
        },
        activities: {
            title: "Atividades",
            titleNew: "atividade",
            read: {
                newActivity: "Nova atividade",
                courseActivities: "Atividades do curso",
                orphanActivities: "Atividades órfãs",
                deleteActivity: "Excluir atividade"
            },
            create: {
                nameActivity: "Nome da atividade",
                repeatTitleAnnotations: "Repetir titulo do documento no quadro de anotações",
                knowledgeBase: "Base de conhecimento",
                knowledgeBaseShare: "Bases de conhecimento são compartilhadas entre os usuários da atividade",
                singleKnowledgeBase: "Uma base de conhecimento para todos os documentos",
                includeClassesValidation: "Incluir classes na validação",
                digitalLibraryDocuments: "Documentos da biblioteca digital",
                yourDocuments: "Seus documentos",
                permissions: "Permissões",
                attributes: {
                    create_annotations: "Criar Anotações",
                    edit_annotations: "Editar Anotações",
                    create_free: "Criar anotações Livre",
                    create_semantic: "Criar anotações semânticas",
                    create_replies: "Criar respostas",
                    edit_replies: "Editar respostas",
                    visible_everybody: "Fazer anotações com visibilidade 'pública'",
                    visible_groups: "Fazer anotações com visibilidade 'moderador'",
                    visible_private: "Fazer anotações com visibilidade 'privada'",
                    see_all_semantic: "Ver os outros membros da base de conhecimento",
                    filter_by_user: "Permitir a filtragem de anotações livres por usuário"
                }
            }
        },
        users: {
            title: "Usuários",
            singleTitle: "usuário",
            addUsers: {
                title: "Adicionar usuários",
                search: "Pesquisa por usuários",
                role: "Papel"
            },
            read: {
                title: "Gerenciamento de usuários",
                table: {
                    id: "ID",
                    name: "Nome",
                    email: "E-mail",
                    role: "Papel"
                }
            },
            edit: {
                title: "Editar usuário"
            }
        },
        courses: {
            title: "Cursos",
            titleNew: "curso",
            create: {
                nameCourse: "Nome do curso",
            },
            edit: "Renomear curso"
        },
        documents: {
            title: "Documentos",
            singleTitle: "documento",
            moodle: "Dados para configuração de atividade externa no Moodle"
        },
        ontologies: {
            title: "Ontologias",
            singleTitle: "ontologia"
        },
        general: {
            name: "Nome",
            moderator: "Moderador",
            student: "Aluno",
            create: "Criar",
            add: "Adicionar",
            edit: "Editar",
            delete: "Excluir",
            reset: "Redefinir",
            email: "E-mail",
            password: "Senha",
            alert: "Ops, não foi possível concluir o solicitação",
            confirmDelete: "Você deseja realmente excluir?",
            confirmDeleteText: "Você deseja realmente excluir este(a) {{item}}?",
            fulfillAll: "Você deve preencher todos os campos",
            title: "Título",
            actions: "Ações",
            item: "Item"
        },
        genericRead: {
            notFound: "Nenhum {{title}} encontrado"
        },
        roles: {
            teacher: "Professor",
            student: "Aluno",
            moderator: "Moderador",
            administrator: "Administrador"
        },
        selectMultiple: {
            leftTitle: "Disponível",
            rightTitle: "Selecionado"
        },
        dragDropFile: {
            title: "Arraste ou clique para selecionar",
            hoverTitle: "Arraste aqui",
            invalidFileType: "Tipo de arquivo inválido"
        }
    },
};
